import styled from 'styled-components'

interface FooterProps {
  className?: string
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  return <FooterContainer className={className}></FooterContainer>
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 40px;
  @media (max-width: 660px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
`

export default Footer
