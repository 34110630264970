import styled from 'styled-components'

import Footer from '~/components/Footer'
import SideNav from '~/components/SideNav'

const AppGrid = styled.div`
  display: grid;
  grid-template-rows: 88px 1fr;
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    'falseheader  falseheader'
    'sidebar      main ';
  height: 100vh;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* single column on small screens */
    grid-template-columns: 1fr;
  }
`

const AppPageComponent = styled.div`
  grid-area: 1 / 1 / -1 / -1;
  overflow: scroll;
  grid-column-start: 1;
  background: ${({ theme }) => `${theme.colors.gradient.primary}`};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* additional padding so footer clears floating nav */
    padding-bottom: 110px;
  }
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const AppSideBar = styled.div`
  grid-area: sidebar;
  overflow: hidden;
  z-index: 100;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* relocate nav to floating button */
    position: fixed;
    bottom: 20px;
    left: 5%;
    right: 5%;
    /* add space for drop-shadow on child element */
    padding: 8px 5px;
  }
`

const ComponentContainer = styled.div`
  flex: 1 0 auto;
`

const AppLayout: React.FunctionComponent = ({ children }) => {
  return (
    <AppGrid>
      <AppSideBar>
        <SideNav />
      </AppSideBar>
      <AppPageComponent>
        <ComponentContainer>{children}</ComponentContainer>
        <Footer />
      </AppPageComponent>
    </AppGrid>
  )
}

export default AppLayout
