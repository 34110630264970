import { RequestHandler } from '@apollo/client'
import { ApolloError, NetworkError } from '@apollo/client/errors'
import { ErrorLink } from '@apollo/client/link/error'
import cookie from 'cookie'
import type { GraphQLError } from 'graphql/error/GraphQLError'

import { LUMOSITY_USER_COOKIE, PLATFORM_WEB } from '~/constants'
import dayjs from '~/libs/dayjs'
import { logToRollbar } from '~/utils/errorUtils'

/**
 * GraphQL Error Code strings
 * @see https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
 */
export enum GraphQLErrorCode {
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

/**
 * Request handler that attaches to header a user JWT stored in a cookie.
 * @param operation GraphQL operation requested (query, mutation, etc.).
 * @param forward Next Link in link chain.
 * @returns Invocation of next link on operation.
 *
 * @see https://www.apollographql.com/docs/react/networking/authentication/#header
 */
export const authHandler: RequestHandler = (operation, forward) => {
  const cookies = cookie.parse(document.cookie)
  const userToken = cookies?.[LUMOSITY_USER_COOKIE]

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: userToken ? `Bearer ${userToken}` : null,
    },
  }))

  return forward(operation)
}

/**
 * Request handler that attaches headers required by lumos-backend.
 * @param operation GraphQL operation requested (query, mutation, etc.).
 * @param forward Next Link in link chain.
 * @returns Invocation of next link on operation.
 */
export const requiredHeadersHandler: RequestHandler = (operation, forward) => {
  const timeZoneOffset = dayjs().format('Z')
  const locale = navigator?.language || 'en-US'
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Platform: PLATFORM_WEB,
      Locale: locale,
      TimeZoneOffset: timeZoneOffset,
    },
  }))

  return forward(operation)
}

const errorMessageStyles = [
  'color: #0e2337',
  'font-size: 12px',
  'border: 1px solid black',
  'border-radius: 5px',
  'padding: 3px 5px',
  'background: #A9A9A9',
].join(';')

/**
 * Logs formatted error message from a GraphQL error to the console.
 * @param err GraphQLError thrown by operation
 * @param type Type of error to log
 */
export const logGraphQlErrorToConsole = (err: GraphQLError, type: GraphQLErrorCode): void => {
  if (process.env.NODE_ENV === 'production') return

  const title = type.split('_').join(' ')

  const hazard = String.fromCodePoint(0x1f6d1)
  const messagePrefix = `${hazard} [GraphQL Error]`

  const message = `${title}: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
  console.error('%c%s', errorMessageStyles, messagePrefix, message)
}

/**
 * Logs formatted error message from a NetworkError to the console.
 * @param err NetworkError thrown during operation
 */
export const logNetworkErrorToConsole = (err: NetworkError): void => {
  if (process.env.NODE_ENV === 'production') return

  const signal = String.fromCodePoint(0x1f4f6)
  const messagePrefix = `${signal} [Network Error]`

  const message = `[Network Error]: ${err?.message}`
  console.error('%c%s', errorMessageStyles, messagePrefix, message)
}

/**
 * Reports error to Rollbar only for client-side errors.
 * @param err GraphQL or Network error thrown during operation
 */
export const logApolloErrorToRollbar = (err: GraphQLError | NetworkError): void => {
  logToRollbar(err)
}

/**
 * Error handler used to create an ApolloLink for application level error handling.
 * Should handle observability concerns.
 * @param param0 {ErrorResponse} ErrorResponse object
 */
export const apolloErrorHandler: ErrorLink.ErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        // TODO: If necessary, handle individual error cases
        case GraphQLErrorCode.BAD_USER_INPUT:
          break
        case GraphQLErrorCode.FORBIDDEN:
          break
        case GraphQLErrorCode.GRAPHQL_PARSE_FAILED:
          break
        case GraphQLErrorCode.GRAPHQL_VALIDATION_FAILED:
          break
        case GraphQLErrorCode.INTERNAL_SERVER_ERROR:
          break
        case GraphQLErrorCode.PERSISTED_QUERY_NOT_FOUND:
          break
        case GraphQLErrorCode.PERSISTED_QUERY_NOT_SUPPORTED:
          break
        case GraphQLErrorCode.UNAUTHENTICATED:
          // Potential opportunity to refresh token and retry??
          // see example: https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
          break
      }
      // Report client-side errors to console
      logGraphQlErrorToConsole(err, err.extensions.code)
      // Report client-side errors to Rollbar
      logApolloErrorToRollbar(err)
    }
  }
  if (networkError) {
    // Report network errors
    logNetworkErrorToConsole(networkError)
    logApolloErrorToRollbar(networkError)
  }
}

/**
 *
 * @param notifyFunction Notifies user of failed operation.
 * @param message Message to display
 * @returns {(error: ApolloError) => void} Apollo Error Handler
 */
export const mutationErrorHandler = (notifyFunction: any, message: string): ((error: ApolloError) => void) => {
  return () => {
    // TODO: Remove/modify condition if showing to user on production/staging
    // TODO: Provide customized `action` component to configuration
    if (process.env.NODE_ENV === 'development') {
      notifyFunction(message, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      })
    }
  }
}

/**
 *
 * @param notifyFunction Notifies user of successful operation.
 * @param message Message to display
 * @returns {(data: any) => void} Success handler that accepts data returned from graphQL operation.
 */
export const mutationSuccessHandler = (notifyFunction: any, message: string): ((data: any) => void) => {
  return () => {
    // TODO: Remove/modify condition if showing to user on production/staging
    // TODO: Provide customized `action` component to configuration
    if (process.env.NODE_ENV === 'development') {
      notifyFunction(message, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      })
    }
  }
}
