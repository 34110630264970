import { InMemoryCache } from '@apollo/client'

import { playGameSoundsVar, showSideNavVar } from '~/graphql/reactive-vars/uiVar'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'

export const createDefaultCache = (): InMemoryCache => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          /**
           * Populate whole queries
           */

          /* MOCKS ****************************************/
          /* END MOCKS ************************************/

          // potential user query
          GetCurrentUser: {
            read: () => {
              const currentUserVal = currentUserVar()
              const UISettings = {
                showSideNav: showSideNavVar(),
                game_sound_on: playGameSoundsVar(),
              }
              return {
                ...currentUserVal,
                ...UISettings,
              }
            },
          },
        },
      },
    },
  })
}

export const cache: InMemoryCache = createDefaultCache()
