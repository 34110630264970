// Based on https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/

import { ApolloClient, ApolloLink, from, HttpLink, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { NextPageContext } from 'next'
import getConfig from 'next/config'

import { cache } from '~/graphql/cache'
import { apolloErrorHandler, authHandler, requiredHeadersHandler } from '~/utils/apolloUtils'

const { publicRuntimeConfig } = getConfig()

const createHttpLink = (ctx?: NextPageContext) => {
  const httpLink = new HttpLink({
    uri: publicRuntimeConfig.graphQL.endpoint, // endpoint should include https:// or http:// for localhost
    credentials: 'include',
    headers: (ctx?.req?.headers as Record<string, string>) || {}, // auth token is fetched on the server side
    fetch,
  })

  return httpLink
}

export default function createApolloClient(initialState: NormalizedCacheObject = {}, ctx?: NextPageContext) {
  const ssrMode = typeof window === 'undefined'
  const connectToDevTools = process.env.NODE_ENV === 'development'

  const httpLink = createHttpLink(ctx)
  const authLink = new ApolloLink(authHandler)
  const requiredHeadersLink = new ApolloLink(requiredHeadersHandler)
  const errorLink: ApolloLink = onError(apolloErrorHandler)
  const retryLink: ApolloLink = new RetryLink({
    attempts: {
      max: 3,
    },
  })

  return new ApolloClient({
    ssrMode,
    link: ssrMode ? from([errorLink, httpLink]) : from([authLink, requiredHeadersLink, errorLink, retryLink, httpLink]),
    cache: cache.restore(initialState),
    connectToDevTools,
  })
}
