// Based on https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/

import { ReactNode } from 'react'

import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { NextPage, NextPageContext } from 'next'
import { AppContext } from 'next/app'
import { useRouter } from 'next/router'

import AppLayout from '~/components/layout/AppLayout'
import EmptyLayout from '~/components/layout/EmptyLayout'
import { LUMOSITY_USER_COOKIE } from '~/constants'
import createApolloClient from '~/lib/apolloClient'
import { parseCookies } from '~/utils/loginUtils'

// Pages to show the SideNav and Footer on, using AppLayout. Includes homepage.
const PAGES_WITH_SIDENAV: string[] = []

interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject> | null
  apolloState: NormalizedCacheObject
  ctx: NextPageContextApp
}

type NextPageContextApp = NextPageContextWithApollo & AppContext
// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null

async function getHeaders(ctx: NextPageContextApp) {
  if (typeof window !== 'undefined') return undefined
  if (typeof ctx.req === 'undefined') return undefined
  // User JWT is stored in cookies
  // Cookie sent on page request from client browser is available on ctx.req
  const cookieData = parseCookies(ctx.req)
  const lumosUserJWT = cookieData?.[LUMOSITY_USER_COOKIE] ?? ''

  return {
    Authorization: `Bearer ${lumosUserJWT}`,
  }
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 * @param  {NextPageContext?} ctx
 */
const initApolloClient = (initialState: NormalizedCacheObject, ctx?: NextPageContext) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, ctx)
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialState)
  }

  return globalApolloClient
}

/**
 * Creates a withApollo HOC
 * that provides the apolloContext
 * to a next.js Page or AppTree.
 * @param  {Object} withApolloOptions
 * @param  {Boolean} [withApolloOptions.ssr=false]
 * @returns {(PageComponent: ReactNode) => ReactNode}
 */
export const withApollo =
  ({ ssr = false } = {}) =>
  (PageComponent: NextPage<any>): ReactNode => {
    const WithApollo = ({
      apolloClient,
      apolloState,
      ...pageProps
    }: {
      apolloClient?: ApolloClient<NormalizedCacheObject>
      apolloState?: NormalizedCacheObject
    }): ReactNode => {
      let client
      if (apolloClient) {
        // Happens on: getDataFromTree & next.js ssr
        client = apolloClient
      } else if (apolloState) {
        // Happens on: next.js csr
        client = initApolloClient(apolloState)
      } else {
        // we should not get here
        client = initApolloClient({})
      }

      const router = useRouter()
      const [, page] = router.pathname.split('/')

      const Layout = PAGES_WITH_SIDENAV.includes(page) ? AppLayout : EmptyLayout

      return (
        <ApolloProvider client={client}>
          <Layout>
            <PageComponent {...pageProps} />
          </Layout>
        </ApolloProvider>
      )
    }

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
      const displayName = PageComponent.displayName || PageComponent.name || 'Component'
      WithApollo.displayName = `withApollo(${displayName})`
    }
    if (ssr || PageComponent.getInitialProps) {
      WithApollo.getInitialProps = async (ctx: NextPageContextApp): Promise<Record<string, unknown>> => {
        const { AppTree } = ctx
        // Initialize ApolloClient, add it to the ctx object so
        // we can use it in `PageComponent.getInitialProp`.

        const headers = await getHeaders(ctx)

        if (headers && ctx.req) {
          ctx.req.headers = headers
        }

        const apolloClient = (ctx.apolloClient = initApolloClient({}, ctx))

        // Run wrapped getInitialProps methods
        let pageProps = {}
        if (PageComponent.getInitialProps) {
          pageProps = await PageComponent.getInitialProps(ctx)
        }

        // Only on the server:
        if (typeof window === 'undefined') {
          // When redirecting, the response is finished.
          // No point in continuing to render
          if (ctx.res && ctx.res.writableEnded) {
            return pageProps
          }

          // Only if ssr is enabled
          if (ssr) {
            try {
              // Run all GraphQL queries
              const { getDataFromTree } = await import('@apollo/client/react/ssr')
              await getDataFromTree(
                <AppTree
                  pageProps={{
                    ...pageProps,
                    apolloClient,
                  }}
                />,
              )
            } catch (error) {
              // Prevent Apollo Client GraphQL errors from crashing SSR.
              // Handle them in components via the data.error prop:
              // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
              console.error('Error while running `getDataFromTree`', error)
            }
          }
        }

        // Extract query data from the Apollo store
        const apolloState = apolloClient.cache.extract()

        return {
          ...pageProps,
          apolloState,
        }
      }
    }

    return WithApollo
  }
