import React from 'react'

import Link from 'next/link'

import ButtonWithTracking, { ButtonWithTrackingProps } from '~/components/ui/ButtonWithTracking'

interface ButtonLinkProps extends ButtonWithTrackingProps {
  href: string
  children?: React.ReactNode
}

const ButtonLinkWithTracking = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ href, children, ...props }, ref) => {
    if (children) {
      return (
        <Link passHref href={href}>
          <ButtonWithTracking ref={ref} {...props}>
            {children}
          </ButtonWithTracking>
        </Link>
      )
    }

    return (
      <Link passHref href={href}>
        <ButtonWithTracking {...props} />
      </Link>
    )
  },
)

export default ButtonLinkWithTracking
