import useLocale from '~/hooks/useLocale'

const useLocalDate = () => {
  const currentDate = new Date()
  const { locale } = useLocale()
  const hour = currentDate.getHours()

  const dateFormatted = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  }).format(currentDate)

  const greetingKey = () => {
    if (hour < 12) {
      return 'goodMorning'
    } else if (hour < 17) {
      return 'goodAfternoon'
    } else {
      return 'goodEvening'
    }
  }

  return {
    hour,
    dateFormatted,
    greetingKey,
  }
}

export default useLocalDate
