import { config } from '@react-spring/web'

/***** SPRINGS *****/
export const openClose = (showSideNav: boolean) => ({
  from: !showSideNav ? { width: '259px' } : { width: '68px' },
  to: !showSideNav ? { width: '68px' } : { width: '259px' },
  config: config.slow,
})
export const slideFlip = (showSideNav: boolean) => ({
  from: { transform: 'translateX(0px) rotate3d(0,0,0, 0deg)' },
  to: { transform: 'translateX(100px) rotate3d(0,1,0, -180deg)' },
  reverse: !showSideNav,
  config: config.slow,
})

export const springs = {
  openClose,
  slideFlip,
}

export default { springs }
